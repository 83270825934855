<template>
  <div :id="id" :style="{ width: width, height: height }"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "myechart",
  props: {
    id: {
      required: true,
      type: String,
    },
    width: {
      required: false,
      default: "100%",
    },
    height: {
      required: false,
      default: "100%",
    },
    options: {
      type: Object,
      default() {
        return {
          title: {
            text: "ECharts",
          },
          tooltip: {},
          xAxis: {
            data: ["data1", "data2", "data3", "data4"],
          },
          yAxis: {},
          series: [
            {
              name: "",
              type: "bar",
              data: [5, 20, 36, 10],
            },
          ],
        };
      },
    },
  },
  mounted() {
    let myChart = echarts.init(document.getElementById(this.id));
    // 绘制图表
    myChart.setOption(this.options);
    window.addEventListener("resize", () => {
      myChart.resize();
    });
  },
};
</script>

<style>
</style>