import instance from "../request/axios.js";

let apiUrl1 = ''
let apiUrl2 = ''
let apiUrl3 = ''
let apiUrl4 = ''
if(process.env.NODE_ENV === 'production') {
  apiUrl1 = 'https://pm.fanyuanwang.cn'
  apiUrl2 = 'https://fg.fanyuanwang.cn'
  apiUrl3 = 'https://ss.fanyuanwang.cn'
  apiUrl4 = 'https://hc.fanyuanwang.cn'
} else {
  apiUrl1 = 'https://point-mall.test-01.54test.cn'
  apiUrl2 = 'https://festival-gifts.test-01.54test.cn'
  apiUrl3 = 'https://staff-security.test-01.54test.cn'
  apiUrl4 = 'https://health-check-management.test-01.54test.cn'
}

// 获取生日礼包
export function GetContractBirthdaySummary(){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl2 + '/api/contract/GetContractBirthdaySummary'
   })
}

// 福利首页企业积分余额
export function PointFlowSummary(params){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl1 + '/api/Flow/Hr/PointFlowSummary',
     params: params
   })
}

// 福利首页节日礼包接口
export function GiftSummary(params){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl2 + '/api/contract/contract_GiftSummary',
     params: params
   })
}

// 福利首页员工商保
export function GetOrderInsuranceSummary(){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl3 + '/api/order/InsuranceSummary',
   })
}

// 福利首页年度体检
export function HrSummary(){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl4 + '/ContractOrder/HrSummary',
   })
}

// 福利首页进行中的福利
export function GetContractDatasSummary(params){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl2 + '/api/contract/GetContractDatasSummary',
     params: params
   })
}

// 福利首页积分发放金额
export function GrantData(params){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl1 + '/api/analysis/hr/grant_data',
     params: params
   })
}

// 福利首页积分充值金额
export function ChargeData(params){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl1 + '/api/analysis/hr/charge_data',
     params: params
   })
}

// 福利首页消费力度统计(消费总金额/员工消费金额)
export function ConsumeData(params){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl1 + '/api/analysis/hr/consume_data',
     params: params
   })
}

// 福利首页员工消费订单数
export function OrderData(params){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl1 + '/api/analysis/hr/order_data',
     params: params
   })
}

// 福利首页员工消费类型占比
export function ProportionData(params){
  return instance.request({
     //发送GET请求
     method: 'get',
     url: apiUrl1 + '/api/analysis/hr/proportion_data',
     params: params
   })
}

// 获取福利已配置按钮
export function WelfareConfigSetData(){
  return instance.request({
    //发送GET请求
    method: 'get',
    url: '/api/Welfarebench/WelfareConfigSetData',
   })
}

// 保存福利工作台配置
export function WelfareConfigSet(data){
  return instance.request({
    //发送GET请求
    method: 'post',
    url: '/api/Welfarebench/WelfareConfigSet',
    data
   })
}

// 获取登录信息
export function GetUserInformation(){
  return instance.request({
       //发送GET请求
       method: 'get',
       url: '/api/Workbench/LoginUser',
   })
}


// 获取工作台配置
export function GetWorkConfigs(){
  return instance.request({
       //发送GET请求
       method: 'get',
       url: '/api/Workbench/GetWorkbenchConfigs',
   })
}

// 获取数据统计
export function GetDataStatistics(id){
  return instance.request({
       //发送GET请求
       method: 'get',
       url: '/api/Workbench/CommonCount',
       params:{
         id:id
       }
   })
}


// 获取图表统计
export function GetChartStatistics(id){
  return instance.request({
       //发送GET请求
       method: 'get',
       url: '/api/Workbench/CommonChart',
       params:{
         id:id
       }
   })
}

// 获取总的未读通知和未处理待办数量
export function GetUnReadNum(){
  return instance.request({
       //发送GET请求
       method: 'get',
       url: '/api/Workbench/GetAllMessageCount'
   })
}

// 获取待办事项
export function GetNeedHandleThing(day){
  return instance.request({
       //发送GET请求
       method: 'get',
       url: '/api/Workbench/BacklogTransactions',
       params:{
        day:day
       }
   })
}

// 获取消息通知
export function GetNewsNotice(day){
  return instance.request({
       //发送GET请求
       method: 'get',
       url: '/api/Workbench/MessageNotifications',
       params:{
        day:day
       }
   })
}


// 获取已配置按钮
export function GetAlreadyConfigured(type){
  return instance.request({
       //发送GET请求
       method: 'get',
       url: '/api/Workbench/ConfigSetData',
       params:{
        modularType:type
       }
   })
}

// 保存工作台配置
export function PostSaveWorkConfigs(data){
  return instance.request({
       //发送GET请求
       method: 'post',
       url: '/api/Workbench/ConfigSet',
       data
   })
}
