<template>
  <div class="hyh-calendar">
    <div class="row-day">
      <div class="day">一</div>
      <div class="day">二</div>
      <div class="day">三</div>
      <div class="day">四</div>
      <div class="day">五</div>
      <div class="day">六</div>
      <div class="day">日</div>
    </div>
    <div class="calendar-date">
      <div
        class="calendar-date-item last-month-date"
        v-for="(date, index) in lastMonth"
        :key="'lastMonth' + index"
      >
        <div class="calendar-date-picker">{{ date }}</div>
      </div>
      <div
        class="calendar-date-item current-month-date"
        :class="{ 'calendar-date-active': currentMonthActiveDate == date }"
        v-for="(date, index) in currentMonth"
        :key="'currentMonth' + index"
        @click="pickCurrentMonthDate(date)"
      >
        <div class="calendar-date-picker " :class="{'today-date-active':Today==date&&month==new Date().getMonth() + 1&&year==new Date().getFullYear()}">
           {{ date }}
          <div v-for="(citem,index2) in NoReadTime" :key="index2" :class="{iconicon: year + '-'+ month + '-' + date == citem}">
          <!-- <div class="iconicon"> -->
           
          </div>
          
        </div>
        
        
      </div>
      <div
        class="calendar-date-item next-month-date"
        v-for="(date, index) in nextMonth"
        :key="'nextMonth' + index"
      >
        <div class="calendar-date-picker">{{ date }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hyhcalendar",
  props: {
    year: {
      required: false,
      default: new Date().getFullYear(),
    },
    month: {
      required: false,
      default: new Date().getMonth() + 1,
    },
    NoReadTimeArr: {
      default:Array,
    },
  },
  data() {
    return {
      lastMonth: [],
      currentMonth: [],
      nextMonth: [],
      currentMonthActiveDate: new Date().getDate(),
      NoReadTime:[], //未读通知的时间
      Today:new Date().getDate(),
    };
  },
  mounted() {
    this.renderCalendar(this.year, this.month);
  },
  watch: {
    year: {
      handler() {
        this.renderCalendar(this.year, this.month);
      },
    },
    month: {
      handler() {
        this.renderCalendar(this.year, this.month);
      },
    },
   NoReadTimeArr(val){
    // let arr= val.map(item => {
    //    return new Date(item).getTime()
    //  });
     this.NoReadTime=val
    }
  },
  methods: {
    renderCalendar(year, month) {
      this.lastMonth = [];
      this.currentMonth = [];
      this.nextMonth = [];
      // month从0开始,所以需-1
      let currentMonth_firstDate = new Date(year, month - 1, 1).getDate(); // 获取当月第一天 -> 日
      let currentMonth_lastDate = new Date(year, month - 1 + 1, 0).getDate(); // 获取当月最后一天 -> 日
      // console.log(currentMonth_lastDate);
      let currentMonth_firstDay = new Date(year, month - 1, 1).getDay(); // 获取当月第一天是周几
      let lastMonth_lastDate = new Date(year, month - 1, 0).getDate(); // 获取上月最后一天 -> 日
      if (currentMonth_firstDay == 0) {
        currentMonth_firstDay = 7;
      }
      for (
        let i = currentMonth_firstDay - 1, j = lastMonth_lastDate;
        i > 0;
        i--, j--
      ) {
        this.lastMonth.push(j);
      }
      this.lastMonth.reverse();
      for (let i = currentMonth_firstDate; i <= currentMonth_lastDate; i++) {
        if(i < 10) {
          i = '0' + i
        }
        this.currentMonth.push(i);
      }
      for (
        let i = 1;
        i <= 6 * 7 - (this.lastMonth.length + this.currentMonth.length);
        i++
      ) {
        this.nextMonth.push(i);
      }
    },
    pickCurrentMonthDate(date) {
      // console.log(date);
      this.$emit("getDay",date)
      this.currentMonthActiveDate = date;
    },
    handleTime(){

    },
  },
};
</script>

<style scoped>
.hyh-calendar {
  width: 100%;
}
.row-day {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
}
.row-day .day {
  width: 14.285%;
  font-size: .75rem;
  font-family: PingFangMedium;
  color: #9c9ea4;
  text-align: center;
  line-height: 1.0625rem;
}
.calendar-date {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.calendar-date-item {
  width: 14.285%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .875rem;
  font-family: PingFangMedium;
  color: #181b24;
  margin-bottom: 1rem;
  cursor: pointer;
}
.last-month-date,
.next-month-date {
  color: #c8c9ce;
}
.calendar-date-picker {
  width: 1.8125rem;
  height: 1.8125rem;
  border-radius: 50%;
  background: inherit;
  text-align: center;
  line-height: 1.75rem;
  position: relative;
}
.calendar-date-active .calendar-date-picker {
  background: #4c80fa;
  color: #ffffff;
}
.calendar-date-picker .iconicon {
}
.calendar-date-picker .iconicon::before{
  display: block;
  content: '';
  width: 6px;
  height: 6px;
  background: red;
  position: absolute;
  right: -2px;
  top: 0;
  border-radius: 50%;
}
.today-date-active  {
  background: #d0d9ecd8;
}
.NoRead{
  width: .375rem;
  height: .375rem;
  background-color:red;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
}
/* .calendar-date-active .calendar-date-picker::after {
  content: "";
  position: absolute;
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
  right: 0;
  top: 0;
  background: #ff8a00;
  display: inline-block;
  border: .0625rem solid #ffffff;
  box-sizing: border-box;
} */
</style>