<template>
  <div id="fyu-workbench" v-loading="isLoading">
    <el-container class="fyu-appcontainer">
      <el-aside height="100%">
        <div class="customer-info full-asside fyu-scroll">
          <div class="customer-info-block customer-info-basic">
            <img
              :src="
                customerInfo.avatarUrl == null
                  ? defaultCustomerImg
                  : customerInfo.avatarUrl
              "
              class="customerImg"
            />
            <p class="customer-name">{{ customerInfo.customerName }}</p>
            <p class="customer-phone">{{ customerInfo.phone }}</p>
            <p class="customer-mail">{{ customerInfo.email }}</p>
          </div>
          <div class="customer-info-block customer-info-company">
            <p class="company-info">企业信息</p>
            <p class="company-shortname">
              简称：{{ customerInfo.customerShort }}
            </p>
            <p class="company-fullname">
              全称：{{ customerInfo.customerName }}
            </p>
            <div class="company-modules">
              <div>开通模块：</div>
              <div class="company-modules-list">
                <div
                  class="company-modules-item"
                  :class="{
                    'company-modules-multi':
                      customerInfo.serviceType.length > 3,
                  }"
                  v-for="(item, index) in customerInfo.serviceType"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="customer-info-block customer-msg">
            <div class="customer-msg-item" @click="gotoNewsOrList(true)">
              <p class="customer-msg-num">
                {{ TotalUntreatedNum.messageNotificationCount || 0 }}
              </p>
              <p class="customer-msg-title">未读通知</p>
            </div>
            <div class="customer-msg-item" @click="gotoNewsOrList(false)">
              <p class="customer-msg-num">
                {{ TotalUntreatedNum.backlogTransactionCount || 0 }}
              </p>
              <p class="customer-msg-title">未处理待办</p>
            </div>
          </div>
        </div>
      </el-aside>
      <el-main class="fyu-maincontainer">
        <el-row class="data-quickaccess">
          <el-col :span="15">
            <div class="data-statistics">
              <div class="block-head">
                数据统计<span class="more-setting" @click="goDataStatistics"
                  ><i class="el-icon-s-tools"></i>更多设置</span
                >
              </div>
              <div class="block-panel">
                <div
                  class="data-statistics-item"
                  v-for="(item, index) in dataStatistics"
                  :key="index"
                >
                  <img
                    :src="dataStatisticsIcon[item.optionImg]"
                    class="data-statistics-icon"
                  />
                  <div class="data-statistics-details">
                    <p class="data-statistics-num">{{ item.num || 0 }}</p>
                    <p class="data-statistics-title">{{ item.configName }}</p>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="quick-access">
              <div class="block-head">
                快捷入口<span class="more-setting" @click="goQuickAccess"
                  ><i class="el-icon-s-tools"></i>更多设置</span
                >
              </div>
              <div class="block-panel">
                <div
                  class="quick-access-item"
                  v-for="(item, index) in quickAccess"
                  @click="quickEnter(item)"
                  :key="index"
                >
                  <img
                    :src="quickAccessIcon[item.optionImg]"
                    class="quick-access-icon"
                  />
                  <p class="quick-access-title">{{ item.configName }}</p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="calendar-msg-todo calendar-msg-todo-lg">
          <el-col :span="24">
            <el-row>
              <el-col :span="8">
                <div class="workbench-calendar">
                  <div class="current-year-month">
                    <el-date-picker
                      ref="calendarDatePicker"
                      v-model="calendarDate"
                      type="month"
                      placeholder="选择月"
                      class="calendar-picker"
                      :clearable="false"
                      @change="setYearMonth"
                    >
                    </el-date-picker>
                    {{ currentYearMonth }}
                  </div>
                  <Hyhcalendar
                    :year="calendarYear"
                    :month="calendarMonth"
                    :NoReadTimeArr="TotalUntreatedNum.months"
                    @getDay="handlegetDay"
                  ></Hyhcalendar>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="workbench-message">
                  <div class="msg-todo-header">
                    <span class="title">消息通知({{ messageList.count }})</span>
                    <div class="more-info" @click="gotoNews">
                      <i class="dot"></i>
                    </div>
                  </div>
                  <div
                    class="msg-todo-body fyu-scroll"
                    v-if="messageList.count > 0"
                  >
                    <div
                      class="msg-todo-item"
                      @click="NewsDetail(item.id)"
                      v-for="(item, index) in messageList.details"
                      :key="index"
                    >
                      <span class="todo-item-date">
                        {{ item.createDate }}
                      </span>
                      <span
                        class="todo-item-title"
                        :class="{ grey: item.isRead }"
                        >{{ item.title }}
                        <i class="point" v-show="item.isRead == false"></i
                      ></span>
                    </div>
                  </div>
                  <el-empty description="暂无数据" v-else>
                    <div slot="image" class="empty-data"></div>
                    <p slot="description" class="empty-data-description">
                      暂无数据
                    </p>
                  </el-empty>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="workbench-todo">
                  <div class="msg-todo-header">
                    <span class="title">待办({{ todoList.count }})</span>
                    <div class="more-info" @click="gotoList">
                      <i class="dot"></i>
                    </div>
                  </div>
                  <div
                    class="msg-todo-body fyu-scroll"
                    v-if="todoList.count > 0"
                  >
                    <div
                      class="msg-todo-item"
                      v-for="(item, index) in todoList.details"
                      :key="index"
                    >
                      <span class="todo-item-date">{{ item.date }}</span>
                      <span
                        class="todo-item-title"
                        :class="{ 'todo-unread': !item.read }"
                        >{{ item.title }}</span
                      >
                    </div>
                  </div>
                  <el-empty description="暂无数据" v-else>
                    <div slot="image" class="empty-todo"></div>
                    <p slot="description" class="empty-data-description">
                      暂无数据
                    </p>
                  </el-empty>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row class="calendar-msg-todo calendar-msg-todo-sm">
          <el-col :span="12">
            <div class="workbench-calendar">
              <div class="current-year-month">
                <el-date-picker
                  ref="calendarDatePicker"
                  v-model="calendarDate"
                  type="month"
                  placeholder="选择月"
                  class="calendar-picker"
                  :clearable="false"
                  @change="setYearMonth"
                >
                </el-date-picker>
                {{ currentYearMonth }}
              </div>
              <Hyhcalendar
                :year="calendarYear"
                :month="calendarMonth"
                :NoReadTimeArr="TotalUntreatedNum.months"
                @getDay="handlegetDay"
              ></Hyhcalendar>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="workbench-message">
              <div class="msg-todo-header">
                <div class="merge-msg-todo">
                  <span
                    class="title merge-msg-title"
                    :class="{ 'unshowing-block': !showingMsg }"
                    @click="showingMsg = true"
                    >消息通知({{ messageList.count || 0 }})</span
                  >
                  <span
                    class="title merge-todo-title"
                    :class="{ 'unshowing-block': showingMsg }"
                    @click="showingMsg = false"
                    >待办({{ todoList.length || 0 }})</span
                  >
                </div>
                <div class="more-info" @click="gotoNewsOrList(showingMsg)">
                  <i class="dot"></i>
                </div>
              </div>
              <div
                class="msg-todo-body fyu-scroll"
                v-show="showingMsg && messageList.count > 0"
              >
                <div
                  class="msg-todo-item"
                  @click="NewsDetail(item.id)"
                  v-for="(item, index) in messageList.details"
                  :key="index"
                >
                  <span class="todo-item-date">
                    {{ item.createDate }}
                  </span>
                  <span class="todo-item-title" :class="{ grey: item.isRead }"
                    >{{ item.title }}
                    <i class="point" v-show="item.isRead == false"></i
                  ></span>
                </div>
              </div>
              <el-empty
                description="暂无数据"
                v-show="showingMsg && messageList.count <= 0"
              >
                <div slot="image" class="empty-data"></div>
                <p slot="description" class="empty-data-description">
                  暂无数据
                </p>
              </el-empty>
              <div
                class="msg-todo-body fyu-scroll"
                v-show="showingMsg == false && todoList.details.length > 0"
              >
                <div
                  class="msg-todo-item"
                  v-for="(item, index) in todoList.details"
                  :key="index"
                >
                  <span class="todo-item-date">
                    {{ item.createDate }}
                  </span>
                  <span class="todo-item-title" :class="{ grey: item.isRead }"
                    >{{ item.title }}
                    <i class="point" v-show="item.isRead == false"></i
                  ></span>
                </div>
              </div>
              <el-empty
                description="暂无数据"
                v-show="showingMsg == false && todoList.details.length <= 0"
              >
                <div slot="image" class="empty-todo"></div>
                <p slot="description" class="empty-data-description">
                  暂无数据
                </p>
              </el-empty>
            </div>
          </el-col>
        </el-row>
        <el-row class="statistical-chart">
          <div class="block-head">
            统计图表<span class="more-setting" @click="goDataChart"
              ><i class="el-icon-s-tools"></i>更多设置</span
            >
          </div>
          <el-row class="AllEchart">
            <div class="chart-canvas chart-canvas-one" v-if="isShowEchar1">
              <Myechart id="chartOne" :options="echartOptionsOne"></Myechart>
            </div>
            <div class="chart-canvas chart-canvas-two" v-if="isShowEchar2">
              <Myechart id="chartTwo" :options="echartOptionsTwo"></Myechart>
            </div>
            <div class="chart-canvas chart-canvas-three" v-if="isShowEchar3">
              <Myechart
                id="chartThree"
                :options="echartOptionsThree"
              ></Myechart>
            </div>
          </el-row>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
// @ is an alias to /src
import Hyhcalendar from "@/components/hyhcalendar";
import Myechart from "@/components/myechart";
import Vue from "vue";
import {
  GetWorkConfigs,
  GetDataStatistics,
  GetChartStatistics,
  GetUnReadNum,
  GetNeedHandleThing,
  GetNewsNotice,
  GetUserInformation,
} from "@/api/index";
import * as echarts from "echarts";
export default {
  name: "fyuworkbench",
  components: { Hyhcalendar, Myechart },
  data() {
    return {
      isLoading: false,
      TotalDescribeEChart: [], //Echart表格的描述
      defaultCustomerImg: require("@/assets/img/customer.png"),
      customerInfo: {},
      // 数据统计
      dataStatisticsIcon: [
        require("@/assets/img/TJ0.png"),
        require("@/assets/img/TJ1.png"),
        require("@/assets/img/TJ2.png"),
        require("@/assets/img/TJ3.png"),
        require("@/assets/img/TJ4.png"),
        require("@/assets/img/TJ5.png"),
        require("@/assets/img/TJ6.png"),
        require("@/assets/img/TJ7.png"),
        require("@/assets/img/TJ8.png"),
        require("@/assets/img/TJ9.png"),
        require("@/assets/img/TJ10.png"),
        require("@/assets/img/TJ11.png"),
        require("@/assets/img/TJ12.png"),
        require("@/assets/img/TJ13.png"),
        require("@/assets/img/TJ14.png"),
        require("@/assets/img/TJ15.png"),
        require("@/assets/img/TJ16.png"),
        require("@/assets/img/TJ17.png"),
        require("@/assets/img/TJ18.png"),
        require("@/assets/img/TJ19.png"),
        require("@/assets/img/TJ20.png"),
      ],
      dataStatistics: [],
      // 快捷入口
      quickAccess: [],
      quickAccessIcon: [
        require("@/assets/img/KJ0.png"),
        require("@/assets/img/KJ1.png"),
        require("@/assets/img/KJ2.png"),
        require("@/assets/img/KJ3.png"),
        require("@/assets/img/KJ4.png"),
        require("@/assets/img/KJ5.png"),
        require("@/assets/img/KJ6.png"),
        require("@/assets/img/KJ7.png"),
        require("@/assets/img/KJ8.png"),
        require("@/assets/img/KJ9.png"),
        require("@/assets/img/KJ10.png"),
        require("@/assets/img/KJ11.png"),
        require("@/assets/img/KJ12.png"),
        require("@/assets/img/KJ13.png"),
        require("@/assets/img/KJ14.png"),
        require("@/assets/img/KJ15.png"),
        require("@/assets/img/KJ16.png"),
        require("@/assets/img/KJ17.png"),
        require("@/assets/img/KJ18.png"),
        require("@/assets/img/KJ19.png"),
        require("@/assets/img/KJ21.png"),
        require("@/assets/img/KJ22.png"),
        require("@/assets/img/KJ23.png"),
        require("@/assets/img/KJ24.png"),
        require("@/assets/img/KJ25.png"),
      ],
      currentYearMonthDay: this.$moment(new Date()).format("YYYY-MM-DD"),
      currentYearMonth: "",
      calendarYear: "",
      calendarMonth: "",
      calendarDate: new Date(),
      TotalUntreatedNum: [], // 未处理和未通知数据
      messageList: [],
      todoList: [],
      showingMsg: true,
      isShowEchar1: false,
      isShowEchar2: false,
      isShowEchar3: false,
      handleNum: [], //图表处理后的数据
      yAxisName: "金额/元",
      echartOptionsOne: {
        title: {
          text: "社保月度账单",
          left: "center",
          top: "top",
          textStyle: {
            color: "#181b24",
            fontWeight: 700,
            fontFamily: "PingFangBold",
            fontSize: 16,
            lineHeight: 22,
          },
        },
        grid: {
          top: 60,
          right: 30,
          bottom: 30,
          left: 50,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            type: "category",
            name: "",
            offset: 0,

            axisLine: {
              show: true,
              lineStyle: {
                color: "#9C9EA4",
                type: "dashed",
              },
            },
            axisLabel: {
              margin: 8,
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
            axisPointer: {
              show: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#F1F1F2",
                type: "dashed",
              },
            },
            boundaryGap: true,
            data: [
              "2021-7",
              "2021-8",
              "2021-9",
              "2021-10",
              "2021-11",
              "2021-12",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "金额/元",
            splitNumber: 5, // 设置y轴刻度间隔个数
            nameTextStyle: {
              color: "#9C9EA4",
              fontSize: 12,
              fontFamily: "PingFang",
              lineHeight: 17,
            },
            nameGap: 20,
            offset: 0,
            // splitNumber: 4,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#9C9EA4",
                type: "dashed",
              },
            },
            axisLabel: {
              margin: 5,
            },
            axisPointer: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "#F1F1F2",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#4C80FA",
            },
            data: [],
          },
        ],
      },
      echartOptionsTwo: {
        title: {
          text: "月度发薪人数",
          left: "center",
          top: "top",
          textStyle: {
            color: "#181b24",
            fontWeight: 700,
            fontFamily: "PingFangBold",
            fontSize: 16,
            lineHeight: 22,
          },
        },
        grid: {
          top: 60,
          right: 30,
          bottom: 30,
          left: 50,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            type: "category",
            name: "",
            offset: 0,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#9C9EA4",
                type: "dashed",
              },
            },
            axisLabel: {
              margin: 5,
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
            axisPointer: {
              show: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#F1F1F2",
                type: "dashed",
              },
            },
            boundaryGap: true,
            data: [
              {
                value:
                  new Date().getFullYear() + "-" + (new Date().getMonth() - 4),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
              {
                value:
                  new Date().getFullYear() + "-" + (new Date().getMonth() - 3),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
              {
                value:
                  new Date().getFullYear() + "-" + (new Date().getMonth() - 2),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
              {
                value:
                  new Date().getFullYear() + "-" + (new Date().getMonth() - 1),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
              {
                value: new Date().getFullYear() + "-" + new Date().getMonth(),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
              {
                value:
                  new Date().getFullYear() + "-" + (new Date().getMonth() + 1),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
            ],
          },
        ],
        yAxis: [
          {
            scale: true,
            type: "value",
            name: "人数",
            splitNumber: 5, // 设置y轴刻度间隔个数
            nameTextStyle: {
              color: "#9C9EA4",
              fontSize: 12,
              fontFamily: "PingFang",
              lineHeight: 17,
            },
            nameGap: 20,
            offset: 0,
            // splitNumber: 4,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#9C9EA4",
                type: "dashed",
              },
            },
            axisLabel: {
              margin: 5,
            },
            axisPointer: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "#F1F1F2",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#4C80FA",
            },
            data: [],
          },
        ],
      },
      echartOptionsThree: {
        title: {
          text: "薪酬月度账单",
          left: "center",
          top: "top",
          textStyle: {
            color: "#181b24",
            fontWeight: 700,
            fontFamily: "PingFangBold",
            fontSize: 16,
            lineHeight: 22,
          },
        },
        grid: {
          top: 60,
          right: 30,
          bottom: 30,
          left: 50,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            type: "category",
            name: "",
            offset: 0,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#9C9EA4",
                type: "dashed",
              },
            },
            axisLabel: {
              margin: 0,
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
            axisPointer: {
              show: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#F1F1F2",
                type: "dashed",
              },
            },
            boundaryGap: true,
            data: [
              {
                value:
                  new Date().getFullYear() + "-" + (new Date().getMonth() - 4),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
              {
                value:
                  new Date().getFullYear() + "-" + (new Date().getMonth() - 3),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
              {
                value:
                  new Date().getFullYear() + "-" + (new Date().getMonth() - 2),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
              {
                value:
                  new Date().getFullYear() + "-" + (new Date().getMonth() - 1),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
              {
                value: new Date().getFullYear() + "-" + new Date().getMonth(),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
              {
                value:
                  new Date().getFullYear() + "-" + (new Date().getMonth() + 1),
                textStyle: {
                  color: "#9C9EA4",
                  fontSize: 12,
                  fontFamily: "PingFang",
                  lineHeight: 17,
                },
              },
            ],
          },
        ],
        yAxis: [
          {
            scale: true,
            type: "value",
            name: "金额/元",
            nameTextStyle: {
              color: "#9C9EA4",
              fontSize: 12,
              fontFamily: "PingFang",
              lineHeight: 17,
            },
            nameGap: 20,
            offset: 0,
            // splitNumber: 4,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#9C9EA4",
                type: "dashed",
              },
            },
            axisLabel: {
              margin: 10,
            },
            axisPointer: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "#F1F1F2",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#4C80FA",
            },
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    async getData() {
      // let IssuedFestival=await reqData.get("/gifts/api/Report/hr_coupon_giveout_count") //已发放福利数
      this.isLoading = true;
      let UserInformation = await GetUserInformation(); //获取个人信息
      this.customerInfo = UserInformation.data;
      let WorkbenchConfigs = await GetWorkConfigs(); //获取工作台配置
      console.log(WorkbenchConfigs);
      let UnReadNum = await GetUnReadNum(); //获取未读通知和待办事项的数量
      this.quickAccess = WorkbenchConfigs.data[1].details;
      this.dataStatistics = WorkbenchConfigs.data[0].details;
      this.TotalDescribeEChart = WorkbenchConfigs.data[2].details;
      this.TotalUntreatedNum = UnReadNum.data;
      this.$loading = true;
      this.handleTotalDescribeEChart(this.TotalDescribeEChart);
      this.getEcharsData();
      this.dataStatistics.forEach(async (item) => {
        let res = await GetDataStatistics(item.requestId);
        Vue.set(item, "num", res.data);
      });
      // this.$axios
      //   .all([
      //     GetDataStatistics(this.dataStatistics[0].requestId),
      //     // GetDataStatistics(this.dataStatistics[1].requestId),
      //     GetDataStatistics(this.dataStatistics[2].requestId),
      //     GetDataStatistics(this.dataStatistics[3].requestId),
      //     GetDataStatistics(this.dataStatistics[4].requestId),
      //   ])
      //   .then(
      //     this.$axios.spread((res1, res3, res4, res5) => {
      //       console.log("res1", res1);
      //       Vue.set(this.dataStatistics[0], "num", res1.data);
      //       // Vue.set(this.dataStatistics[1], "num", res2.data);
      //       Vue.set(this.dataStatistics[2], "num", res3.data);
      //       Vue.set(this.dataStatistics[3], "num", res4.data);
      //       Vue.set(this.dataStatistics[4], "num", res5.data);
      //     })
      //   );

      // this.dataStatistics[5].num=IssuedFestival.data
    },
    async getNeedHandleThingDate() {
      let NeedHandleThing = await GetNeedHandleThing(this.currentYearMonthDay); //获取待办事项数据
      let NewsNotice = await GetNewsNotice(this.currentYearMonthDay); //获取消息通知数据
      this.todoList = NeedHandleThing.data;
      this.messageList = NewsNotice.data;
    },
    openMall() {
      window.open("http://fanyuancloud.com/mall");
    },
    getEcharsData() {
      this.TotalDescribeEChart.forEach(async (item, index) => {
        let res = await GetChartStatistics(item.requestId);
        // this.DataEChart.push(EcharData?res.data:EcharData);
        this.handleNum = res.data;
        if (item.configName == "社保月度账单") {
          // 判断有没有超过100000的数据
          let isBigNum = this.handleNum.find((item) => {
            return item >= 100000;
          });
          // 如果有，转化单位成万/元
          if (isBigNum) {
            this.handleNum = this.handleNumData(this.handleNum);
            this.yAxisName = "金额/万元";
          }
          let myChart1 = echarts.init(document.getElementById("chartOne"));
          myChart1.setOption({
            series: [
              {
                // 根据名字对应到相应的系列
                data: this.handleNum,
              },
            ],
            xAxis: { data: this.TotalDescribeEChart[index].months },
            yAxis: [
              {
                scale: true,
                name: this.yAxisName,
              },
            ],
          });
        }
        if (item.configName == "月度发薪人数") {
          let myChart2 = echarts.init(document.getElementById("chartTwo"));
          myChart2.setOption({
            series: [
              {
                // 根据名字对应到相应的系列
                data: this.handleNum,
              },
            ],
            xAxis: { data: this.TotalDescribeEChart[index].months },
            yAxis: [
              {
                scale: true,
              },
            ],
          });
        }
        if (item.configName == "薪酬月度账单") {
          this.yAxisName = "金额/元";
          // 判断有没有超过100000的数据
          let isBigNum = this.handleNum.find((item) => {
            return item >= 100000;
          });
          // 如果有，转化单位成万/元
          if (isBigNum) {
            this.handleNum = this.handleNumData(this.handleNum);
            this.yAxisName = "金额/万元";
          }
          let myChart3 = echarts.init(document.getElementById("chartThree"));
          myChart3.setOption({
            series: [
              {
                // 根据名字对应到相应的系列
                data: this.handleNum,
              },
            ],
            xAxis: { data: this.TotalDescribeEChart[index].months },
            yAxis: [
              {
                name: this.yAxisName,
                scale: true,
              },
            ],
          });
        }
      });
      this.isLoading = false;
    },
    setYearMonth(val) {
      let d = val == undefined ? new Date() : new Date(val);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      this.calendarYear = year;
      this.calendarMonth = month >= 10 ? month : "0" + month;
      this.currentYearMonth = year + "-" + this.calendarMonth;
    },
    handlegetDay(val) {
      this.currentYearMonthDay = this.currentYearMonth + "-" + val;
      this.todoList.details = [];
      this.messageList.details = [];
      this.getNeedHandleThingDate();
    },
    // 处理大于100000的数据
    handleNumData(arr) {
      let arr2 = arr.map((item) => {
        return (item / 10000).toFixed(2);
      });
      console.log(arr2);
      return arr2;
    },

    // 处理表格数据
    handleTotalDescribeEChart(item) {
      for (let i = 0; i < item.length; i++) {
        if (item[i].configName == "社保月度账单") {
          this.isShowEchar1 = true;
        } else if (item[i].configName == "月度发薪人数") {
          this.isShowEchar2 = true;
        } else if (item[i].configName == "薪酬月度账单") {
          this.isShowEchar3 = true;
        }
      }
      // item.forEach(element => {
      //   if(element.configName=="月度发薪人数"){
      //    this.isShowEchar1=true
      //  }
      //  if(element.configName=="薪酬月度账"){
      //   this.isShowEchar2=true
      //  }if(element.configName=="社保月度账单"){
      //   this.isShowEchar3=true
      //  }
      // });
    },
    // 点击快捷入口跳转
    quickEnter(item) {
      window.parent.postMessage(
        {
          type: "quickEnter",
          data: item,
        },
        "*"
      );
    },
    goDataStatistics() {
      this.$router.push("/DataStatistics");
    },
    goQuickAccess() {
      this.$router.push("/QuickAccess");
    },
    goDataChart() {
      this.$router.push("/dataChart");
    },
    // 跳转消息通知详情
    NewsDetail(id) {
      window.parent.postMessage(
        {
          data: id,
          type: "NewsDetail",
        },
        "*"
      );
    },

    // 跳转消息通知
    gotoNews() {
      window.parent.postMessage(
        {
          data: "news",
        },
        "*"
      );
    },
    // 分辨率大屏时点击跳转查看更多
    gotoNewsOrList(val) {
      // true  点击消息通知的 ...
      // false  点击待办的 ...
      if (val == true) {
        window.parent.postMessage(
          {
            data: "news",
          },
          "*"
        );
      } else {
        window.parent.postMessage(
          {
            data: "ToDoDetail",
          },
          "*"
        );
      }
    },
    // 跳转待办
    gotoList() {
      window.parent.postMessage(
        {
          data: "ToDoDetail",
        },
        "*"
      );
    },
  },
  mounted() {
    this.setYearMonth();
    this.getData();
    this.getNeedHandleThingDate();
  },
};
</script>
<style scoped>
@import url("../assets/css/workbench/header.css");
@import url("../assets/css/workbench/aside.css");
@import url("../assets/css/workbench/main.css");
#fyu-workbench {
  background: rgba(244, 245, 249, 1);
  height: 100%;
}
.fyu-appcontainer {
  height: 100%;
}
.fyu-maincontainer {
}
@media screen and (max-width: 1360px) {
  .fyu-maincontainer {
  }
}
</style>
