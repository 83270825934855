import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const router = new VueRouter({
routes: [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/DataStatistics',
    name: 'DataStatistics',
    component: () => import(/* webpackChunkName: "datastatistics" */ '@/views/Setting/DataStatistics.vue')
  },
  {
    path: '/QuickAccess',
    name: 'QuickAccess',
    component: () => import(/* webpackChunkName: "quickaccess" */ '@/views/Setting/QuickAccess.vue')
  },
  {
    path: '/dataChart',
    name: 'dataChart',
    component: () => import(/* webpackChunkName: "quickaccess" */ '@/views/Setting/dataChart.vue')
  },
  {
    path: '/Welfare',
    name: 'Welfare',
    component: () => import(/* webpackChunkName: "quickaccess" */ '@/views/Setting/Welfare.vue')
  },
  {
    path: '/MoreSetting',
    name: 'MoreSetting',
    component: () => import(/* webpackChunkName: "quickaccess" */ '@/views/Setting/MoreSetting.vue')
  },
]
})


export default router