import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios"
import {
  Row, Col, Message, MessageBox, Notification, Loading, Dialog, DatePicker, Backtop, Icon,
  Container, Header, Aside, Main, Calendar, Badge, Dropdown, DropdownMenu, DropdownItem,
  Skeleton, SkeletonItem, Tooltip, Empty, Menu, Submenu, MenuItem, Select, Option,  Form, FormItem,
  Button, Input
} from 'element-ui';
import commonjs from './assets/common'
// import moment from "moment"
var moment = require('moment');

Vue.config.productionTip = false;

Vue.use(Input)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Option)
Vue.use(Select)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(DatePicker)
Vue.use(Backtop)
Vue.use(Icon)
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Calendar);
Vue.use(Badge);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Tooltip);
Vue.use(Empty)
Vue.use(Loading.directive);

import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts
Vue.prototype.$ELEMENT = { size: 'small' };
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$loading = Loading.service;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.commonjs = commonjs;
Vue.prototype.$set = Vue.set;
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
